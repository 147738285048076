

































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  PropType,
} from '@vue/composition-api'

interface ProcessNav {
  items: {
    label?: string
    text?: string
    icon: string
  }[]
}

export default defineComponent({
  name: 'ProcessNav',
  components: {},
  props: {
    content: {
      type: Object as PropType<ProcessNav>,
      required: true,
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const rootRef = ref<HTMLElement | null>(null)
    const isSticky = ref(false)
    const navHeight = 60
    let observer: InstanceType<typeof IntersectionObserver>

    const initSticky = () => {
      observer = new IntersectionObserver(
        ([e]) => {
          isSticky.value = e.intersectionRatio < 1 && window.scrollY > 0
          document.body.classList.toggle('sticky-dropdown', isSticky.value)
        },
        { threshold: [1], rootMargin: `-${navHeight}px 0px` }
      )

      observer.observe(rootRef.value!)
    }

    const onClick = (id?: string) => {
      if (!id) {
        return
      }

      const el = document.getElementById(id)
      const elTop = el!.getBoundingClientRect().top + window.scrollY
      const offset = navHeight + rootRef.value!.clientHeight + 180

      window.scrollTo({
        top: elTop - offset,
        left: 0,
        behavior: 'smooth',
      })
    }

    onMounted(() => {
      if (props.sticky) {
        initSticky()
      }
    })

    onUnmounted(() => {
      if (observer) {
        observer.unobserve(rootRef.value!)
      }
    })

    return {
      rootRef,
      isSticky,
      onClick,
    }
  },
})
