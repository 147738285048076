


































import gsap from 'gsap'
import { onUnmounted, ref, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'UiModal',
  components: {},
  props: {
    showModal: {
      type: Boolean || null,
      required: false,
      default: null,
    },
  },
  setup(_props, ctx) {
    // Refs
    const isVisible = ref(false)
    const rootElRef = ref<HTMLElement | null>(null)

    // Methods
    // Show modal and init events
    const show = () => {
      isVisible.value = true
      window.addEventListener('keyup', onKeyUp)
      // ui.toggleScroll(false)
      ctx.emit('show')
    }

    const hide = () => {
      // Hide modal and cancel events
      isVisible.value = false
      window.removeEventListener('keyup', onKeyUp)
      ctx.emit('hide')
      // ui.toggleScroll(true)
    }

    // Event handling
    // Transition modal in
    const onEnter = (el: Element, done: () => void) => {
      const innerEl = el.querySelector('.modal-inner')
      const tl = gsap.timeline({
        onComplete: () => {
          done()
        },
      })

      tl.fromTo(
        el,
        { opacity: 0 },
        {
          opacity: 1,
        }
      )

      innerEl &&
        tl.fromTo(
          innerEl,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
          },
          '<=+0.1'
        )
    }

    const onLeave = (el: Element, done: () => void) => {
      const innerEl = el.querySelector('.modal-inner')
      const tl = gsap.timeline({
        onComplete: () => {
          done()
        },
      })

      innerEl &&
        tl.fromTo(
          innerEl,
          { opacity: 1, y: 0 },
          {
            opacity: 0,
            y: 50,
            duration: 0.2,
          }
        )

      tl.fromTo(
        el,
        { opacity: 1 },
        {
          opacity: 0,
          duration: 0.2,
        }
      )
    }

    const onAfterLeave = () => ctx.emit('after-leave')

    // On close, remove lightbox
    const onClose = () => {
      hide()
    }

    // Handles key up event
    // Check if escape has been pressed
    const onKeyUp = (event: KeyboardEvent) => {
      const { key } = event

      switch (key) {
        case 'Escape':
          onClose()
          break
        default:
          break
      }
    }

    onUnmounted(() => {
      // Remove keyup event
      window.removeEventListener('keyup', onKeyUp)
    })

    return {
      isVisible,
      rootElRef,
      hide,
      show,
      onEnter,
      onLeave,
      onAfterLeave,
      onClose,
    }
  },
})
