
























import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import HeroDefault from '@/components/hero/Default.vue'
import UiFlexible from '@/components/ui/Flexible.vue'
import UiPill from '@/components/ui/Pill.vue'

export default defineComponent({
  name: 'CreationSingle',
  components: { HeroDefault, UiFlexible, UiPill },
  props: {},

  setup() {
    const isHidden = ref(true)

    onMounted(() => {
      isHidden.value = false
    })

    return {
      isHidden,
      ...useGetters(['content']),
    }
  },
})
