var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"magazine-category"},[_c('div',{staticClass:"magazine-category__heading wrapper"},[_c('GCrumbs'),_c('GTitle',{staticClass:"h1--long m-s",attrs:{"content":_vm.content.header.displayTitle}})],1),(
      _vm.content.testimonials &&
      !_vm.content.testimonials.displayAfterNews &&
      _vm.content.testimonials.items
    )?_c('FlexibleTestimonials',{staticClass:"magazine-category__testimonials m-m",attrs:{"content":_vm.content.testimonials,"open":false}}):_vm._e(),_c('div',{staticClass:"wrapper mb-l"},[_c('GIntro',{attrs:{"content":_vm.content.header.intro}})],1),(_vm.content.highlighted && _vm.content.highlighted.title)?_c('div',{staticClass:"magazine-category__highlighted wrapper"},[_c('MagazineCard',{attrs:{"content":_vm.content.highlighted,"isHighlighted":true,"tag":"div"}})],1):_vm._e(),_c('MagazineGrid',{staticClass:"wrapper",class:{ 'col-2': _vm.showSubcategories },attrs:{"items":_vm.showSubcategories ? _vm.content.subCategories : _vm.news}}),_c('transition',{attrs:{"name":"g-fade"}},[(!_vm.showSubcategories && _vm.news.length < _vm.content.news.total_posts)?_c('div',{staticClass:"magazine-category__button mt-l"},[_c('GAction',{attrs:{"content":{ label: _vm.$t('load-more-articles'), tag: 'button' }},nativeOn:{"click":function($event){return _vm.loadMore.apply(null, arguments)}}})],1):_vm._e()]),(
      _vm.content.testimonials &&
      _vm.content.testimonials.displayAfterNews &&
      _vm.content.testimonials.items
    )?_c('FlexibleTestimonials',{staticClass:"magazine-category__testimonials m-m",attrs:{"content":_vm.content.testimonials,"open":false}}):_vm._e(),(_vm.content.htmltext)?_c('ProjectContent',{staticClass:"magazine-category__content",attrs:{"content":_vm.content.htmltext,"open":false}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }