

































import type { Picture } from '@/inc/types'

import { defineComponent, PropType } from '@vue/composition-api'

import UiPicture from '@/components/ui/Picture.vue'

export interface CreationCard {
  title: string
  htmltext: string
  picture: Picture
  url: string
}

export default defineComponent({
  name: 'CreationCard',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<CreationCard>,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    return {}
  },
})
