




















import type { FaqItem as FaqItemType } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

import FaqItem from '@/components/faq/Item.vue'

interface FlexibleFaq {
  title: string
  subtitle?: string
  questions: FaqItemType[]
}

export default defineComponent({
  name: 'FlexibleFaq',
  components: { FaqItem },
  props: {
    content: {
      type: Object as PropType<FlexibleFaq>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
