


































import type { ContactStep } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ContactStep',
  props: {
    content: {
      type: Object as PropType<ContactStep>,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, ctx) {
    const { $route } = ctx.root
    const isLocked = props.index > parseInt($route.params.step, 10)
    const [routePrefix] = $route.name!.split('-')

    return {
      isLocked,
      routePrefix,
    }
  },
})
