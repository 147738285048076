





























import { defineComponent, PropType } from '@vue/composition-api'

import Carousel from '@/components/Carousel.vue'
import CreationCard, {
  CreationCard as Card,
} from '@/components/creation/Card.vue'

interface CreationList {
  title: string
  categories: Card[]
}

export default defineComponent({
  name: 'CreationList',
  components: {
    Carousel,
    CreationCard,
  },
  props: {
    content: {
      type: Object as PropType<CreationList>,
      required: true,
    },
  },

  setup(props) {
    const carouselItems = props.content.categories?.map(item => ({
      content: item,
      carouselKey: item.title,
    }))

    return { carouselItems }
  },
})
