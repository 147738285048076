







































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { ValidationProvider, setInteractionMode, extend } from 'vee-validate'
import { email, required, oneOf } from 'vee-validate/dist/rules.umd.js'

import FormFeedback from '@/components/form/Feedback.vue'

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'form-required',
})
extend('email', {
  ...email,
  message: 'form-email',
})
extend('oneOf', {
  ...oneOf,
  message: 'form-showroom-error',
})
extend('date', {
  validate: value => {
    const date = new Date()
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = date.getFullYear()
    const today = `${dd}-${mm}-${yyyy}`

    const d1 = today.split('-')
    const c = value.split('-')

    const from = new Date(
      parseInt(d1[2], 10),
      parseInt(d1[1], 10) - 1,
      parseInt(d1[0], 10)
    ) // -1 because months are from 0 to 11
    const check = new Date(c[0], parseInt(c[1], 10) - 1, c[2])

    return from < check
  },
  message: 'form-date',
})
extend('minmax', {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validate(value, { min, max }) {
    const { length }: { length: number } = value

    // NB: min/max are defined in the rules prop
    if (length < min) {
      return 'form-min'
    } else if (length > max) {
      return 'form-max'
    }

    return true
  },
  params: ['min', 'max'],
})

export default defineComponent({
  name: 'FormInput',
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormFeedback,
  },
  props: {
    data: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    modifiers: {
      type: String,
      required: false,
    },
    preValue: {
      type: String,
      required: false,
    },
    length: {
      type: Number,
      required: false,
      default: 320,
    },
    required: {
      type: Boolean,
      required: false,
    },
    acceptedValues: {
      type: Array as () => string[],
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const value = props.data
    const name = typeof ctx.attrs.name === 'string' ? ctx.attrs.name : 'input'

    const onChange = e => {
      ctx.emit('change', e.target.value)
    }

    const onInput = e => {
      ctx.emit('input', e.target.value)
    }

    return {
      value,
      name,
      onChange,
      onInput,
      ...useGetters(['currentLang']),
    }
  },
})
