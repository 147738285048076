import gsap from 'gsap'

// To do: import DirectiveBinding from vue?
interface AppearBinding {
  name: string
  rawName?: string
  expression?: string
  modifiers: any
  def?: any
  value?: (el: HTMLElement) => void
}

// Intersection observer options
const options = {
  rootMargin: '-25% 0%',
  threshold: [0, 0.5, 0.75, 1],
  once: true,
}

// Default callback (fade in up)
export const onAppearDefault = (el: Element, additionalVars = {}) =>
  gsap.fromTo(
    el,
    { opacity: 0 },
    { opacity: 1, duration: 1.2, ...additionalVars }
  )

/*
 * Observes element and fires callback when element is intersecting
 * Use: v-appear="onAppear"
 */
export default {
  bind(el: HTMLElement, binding: AppearBinding) {
    const appearFunction =
      typeof binding.value === 'function' ? binding.value : onAppearDefault
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Fire binded callback
          appearFunction(el)

          // Removes element from observer
          observer.unobserve(el)
        }
      })
    }
    const observer = new IntersectionObserver(callback, options)

    // Visually hide item
    gsap.set(el, { opacity: 0 })

    // Observe element
    observer.observe(el)
  },
}
