var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-archive-content"},[_c('div',{staticClass:"project-archive__heading-outer wrapper"},[_c('GCrumbs'),_c('div',{staticClass:"project-archive__heading mt-xs mb-s"},[_c('GTitle',{staticClass:"h1--long mb-s",attrs:{"content":_vm.content.displayTitle}}),(_vm.content.intro)?_c('div',{ref:"intro",staticClass:"intro small",domProps:{"innerHTML":_vm._s(_vm.content.intro)}}):_vm._e()],1)],1),_c('ProjectCategories',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayCategory),expression:"displayCategory"}],key:_vm.displayCategory,attrs:{"content":{
      categories: {
        label: _vm.content.categories.label,
        placeholder: _vm.$t('all-categories').toString(),
        items: [
          { label: _vm.$t('all-categories'), value: 'all' } ].concat( _vm.content.categories.items ),
      },
      subCategories: {
        label: _vm.content.labels.subcategories,
        placeholder: _vm.content.labels.allSubCategories,
      },
    },"auto-redirect":false},on:{"category:change":function($event){return _vm.onCategoryChange($event)},"subcategory:change":function($event){return _vm.onSubcategoryChange($event)}}}),(_vm.content.highlighted && _vm.content.highlighted.title)?_c('div',{staticClass:"project-archive__highlighted wrapper mt-m"},[_c('MagazineCard',{attrs:{"content":Object.assign({}, _vm.content.highlighted,
        {link: {
          url: _vm.content.highlighted.link.url,
          label: _vm.content.highlighted.title,
        }})}})],1):_vm._e(),_c('div',{staticClass:"project-archive__grid wrapper pt-m pb-xl"},[(_vm.content.realizations.content.length)?_c('ProjectGrid',{ref:"grid",attrs:{"category":_vm.category,"subcategory":_vm.subcategory},on:{"project:click":function($event){return _vm.openPopup($event)},"project:appear":_vm.onProjectAppear,"loadmore":_vm.onLoadMore}}):_c('div',{staticClass:"project-archive__grid__loadmore mt-xl"},[_vm._v(" "+_vm._s(_vm.$t('no-projects'))+" ")])],1),(_vm.popupContent)?_c('GPopup',{attrs:{"content":_vm.popupContent,"template":"project"},on:{"popup:close":_vm.closePopup}}):_vm._e(),(_vm.content.htmltext)?_c('ProjectContent',{attrs:{"content":_vm.content.htmltext},on:{"open":_vm.onReadmore}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }