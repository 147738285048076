var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContactStepContainer',{staticClass:"step-personal",attrs:{"title":_vm.content.title,"index":1,"total":3},scopedSlots:_vm._u([(_vm.content.headline)?{key:"after-title",fn:function(){return [_c('div',{staticClass:"step-personal__subtitle wrapper"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"step-personal__subtitle__icon icon",attrs:{"symbol":"about-wink","size":"0 0 40 40"}}),_c('div',{staticClass:"step-personal__subtitle__content",domProps:{"innerHTML":_vm._s(_vm.content.headline)}})])]},proxy:true}:null,{key:"content",fn:function(){return [_c('ValidationObserver',{staticClass:"wrapper",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form mt-m",attrs:{"method":"POST","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('fieldset',{staticClass:"form__fieldset"},_vm._l((_vm.content.inputs),function(item){return _c('div',{key:("form-input-" + (item.name)),class:("form__input " + (item.slug) + " " + (item.modifiers))},[(item.slug == 'input')?_c('FormInput',{attrs:{"type":item.type,"name":item.name,"label":item.label,"placeholder":item.placeholder,"required":item.required,"length":item.name === 'city' ? 40 : undefined,"data":_vm.savedStep[item.name]},on:{"change":function($event){return _vm.onInputChange($event, item.name)}}}):(item.slug == 'select' && item.options)?_c('FormSelect',{attrs:{"name":item.name,"options":item.options,"label":item.label,"required":item.required,"placeholder":item.placeholder,"value":_vm.savedStep[item.name]},on:{"input":function($event){return _vm.onInputChange($event, item.name)}}}):_vm._e(),(item.tooltip)?_c('FormSideTooltip',{attrs:{"content":item.tooltip}}):_vm._e()],1)}),0),_c('fieldset',{staticClass:"form__confirmation m-m"},[_vm._l((_vm.content.inputs),function(item,index){return [(item.slug == 'checkbox')?_c('FormCheckbox',{key:("form-checkbox-" + index),class:((item.slug) + " " + (item.modifiers)),attrs:{"name":item.name,"required":item.required,"checked":_vm.savedStep[item.name]},on:{"change":function($event){return _vm.onInputChange($event, item.name)}}},[_c('span',{domProps:{"innerHTML":_vm._s(item.label)}}),(item.required)?_c('span',{staticClass:"form-label__required"},[_vm._v("*")]):_vm._e()]):_vm._e()]})],2),(invalid)?_c('FormFeedback',{staticClass:"form__feedback",attrs:{"content":"form-incomplete","icon":"alert"}}):_vm._e(),_c('fieldset',{staticClass:"form__send"},[_c('div',{staticClass:"form__send__action"},[_c('GAction',{attrs:{"content":{
                label: _vm.content.back.label,
                icon: 'leftArrow',
                url: _vm.content.prev,
                modifiers: ['reverse', 'btn'],
              }}})],1),_c('div',{staticClass:"form__send__action"},[_c('GAction',{staticClass:"submit__btn",attrs:{"content":{
                label: _vm.content.submit.label,
                icon: _vm.content.submit.icon,
                type: 'submit',
                tag: 'button',
                modifiers: ['filled', 'btn'],
              },"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.onSubmitClick()}}})],1)])],1)]}}])})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }