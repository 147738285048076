







































































import type { ResourceSingle } from '@/inc/store/modules/resource'

import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { cache } from '@/inc/utils'
import { getApiUrl } from '@/inc/app.config'

import MagazineCard from '@/components/magazine/Card.vue'
import MagazineGrid from '@/components/magazine/Grid.vue'
import ProjectContent from '@/components/project/Content.vue'
import FlexibleTestimonials from '@/components/flexible/Testimonials.vue'

interface MagazineResource extends Omit<ResourceSingle, 'content'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: Record<string, any>
}

export default defineComponent({
  name: 'MagazineCategory',
  components: {
    MagazineCard,
    MagazineGrid,
    ProjectContent,
    FlexibleTestimonials,
  },
  props: {},

  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { $route } = ctx.root
    const showSubcategories =
      content.value.subCategories?.length && $route.params.category
    const news = ref(content.value.news?.content)
    let currentPage = 1

    // Fetch next page
    const loadMore = async () => {
      currentPage += 1
      let url = `${getApiUrl()}/magazine`

      // Add category
      if ($route.params.category) {
        url += `/${$route.params.category}`
      }

      // Add page
      url += `?page=${currentPage}`

      try {
        const res = await cache.fetch(ctx.root.$route.fullPath, url)
        const data = res.data as unknown as MagazineResource

        if (data.content && data.content.news) {
          const newItems = data.content?.news

          news.value.push(...newItems.content)
        }
      } catch (e) {
        console.error('[loadMore]', url, e)
      }
    }

    return {
      content,
      showSubcategories,
      news,
      currentPage,
      loadMore,
    }
  },
})
