








































import { defineComponent, PropType, ref } from '@vue/composition-api'

import { PCategory } from '@/inc/types'

import FormDropdown from '@/components/form/Dropdown.vue'

interface ProjectCategories {
  categories: {
    items: PCategory[]
    label: string
    placeholder: string
  }
  subCategories?: {
    label: string
    placeholder: string
  }
}

export default defineComponent({
  name: 'ProjectCategories',
  components: {
    FormDropdown,
  },
  props: {
    content: {
      type: Object as PropType<ProjectCategories>,
      required: true,
    },
    autoRedirect: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const isSticky = ref(false)
    const navHeight = 60
    const { $route, $router } = ctx.root
    const { category: routeCategory } = $route.params
    let distanceToTop: number
    let currentCategory: PCategory | null = null

    if (routeCategory) {
      currentCategory =
        props.content.categories.items.find(
          category =>
            category.value === `categorie-${routeCategory}` ||
            category.value === routeCategory
        ) || null
    }

    console.log(currentCategory)

    const onScroll = (_x: number, y: number) => {
      // Round value because of iOS bug that adds/removes .25px to the value
      const newdistanceToTop = Math.round(el.value!.getBoundingClientRect().top)
      const paddingTop = window.innerWidth < 1024 ? 0 : 40

      if (distanceToTop === newdistanceToTop) {
        return
      }

      distanceToTop = newdistanceToTop + y

      isSticky.value = y + navHeight === distanceToTop + paddingTop
      document.body.classList[isSticky.value ? 'add' : 'remove'](
        'sticky-dropdown'
      )
    }

    const onInput = (value: string, isSubcategory = false) => {
      if (value === routeCategory) {
        return
      }

      if (!props.autoRedirect) {
        ctx.emit(`${isSubcategory ? 'subcategory' : 'category'}:change`, value)

        return
      }

      const { lang, endpoint } = $route.params
      let to = `/${lang}/${endpoint}/`

      if (isSubcategory) {
        to += `${routeCategory}/${value}/`
      } else {
        to += `${value}/`
      }

      $router.push(to)
    }

    return {
      el,
      isSticky,
      currentCategory,
      onScroll,
      onInput,
    }
  },
})
