
















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import HeroDefault from '@/components/hero/Default.vue'
import CreationCard from '@/components/creation/Card.vue'

export default defineComponent({
  name: 'CreationArchive',
  components: {
    HeroDefault,
    CreationCard,
  },
  props: {},

  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
