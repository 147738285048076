


















































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { throttle } from 'throttle-debounce'

import Carousel from '@/components/Carousel.vue'
import UiModal from '@/components/ui/Modal.vue'

interface Testimonial {
  name: string
  city: string
  text: string
}

interface Testimonials {
  title: string
  items: Testimonial[]
}

export default defineComponent({
  name: 'FlexibleTestimonials',
  components: {
    Carousel,
    UiModal,
  },
  props: {
    content: {
      type: Object as PropType<Testimonials>,
      required: true,
    },
  },

  setup(props, ctx) {
    const rootEl = ref<HTMLElement | null>(null)
    const carousel = ref<InstanceType<typeof Carousel> | null>(null)
    const progress = ref(0)
    const modal = ref<InstanceType<typeof UiModal> | null>(null)
    const modalArticle = ref<Testimonial | null>(null)
    const carouselItems = props.content.items.map(item => ({
      content: item,
      carouselKey: `${item.name}-${item.city}`,
    }))
    const textLimit = ref(0)
    let scrollerEl: HTMLElement | undefined
    let scrollerWidth = 0

    // Update values based on viewport
    const setValues = () => {
      if (!scrollerEl) {
        return
      }

      const { scrollWidth, clientWidth } = scrollerEl

      scrollerWidth = scrollWidth - clientWidth

      // Update limit based on css property
      if (rootEl.value) {
        const maxLines = window
          .getComputedStyle(rootEl.value)
          .getPropertyValue('--max-lines')

        textLimit.value = parseInt(maxLines, 10) * 40
      }
    }

    const onResize = throttle(500, () => setValues())

    // Update progress element by watching the scroller element
    const onTimelineScroll = throttle(100, () => {
      if (!scrollerEl) {
        return
      }

      const { scrollLeft } = scrollerEl

      progress.value = (scrollLeft / scrollerWidth) * 100
    })

    // Open modal with the full article
    const onItemClick = (item: { content: Testimonial }) => {
      modalArticle.value = item.content

      // Wait for prop to be updated
      ctx.root.$nextTick(() => {
        modal.value?.show()
      })
    }

    const onModalAfterLeave = () => (modalArticle.value = null)

    onMounted(() => {
      scrollerEl = carousel.value?.scrollerRef?.$el as HTMLElement | undefined
      scrollerEl?.addEventListener('scroll', onTimelineScroll)
      setValues()
    })

    onUnmounted(() => {
      scrollerEl?.removeEventListener('scroll', onTimelineScroll)
    })

    return {
      rootEl,
      carousel,
      progress,
      modal,
      modalArticle,
      carouselItems,
      textLimit,
      onResize,
      onItemClick,
      onModalAfterLeave,
    }
  },
})
